/*Pagination*/
.paginationButtons {
    display: flex;
    height: 30px;
    list-style: none;
    justify-content: center;
    transition: 300ms ease-in-out;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.paginationButtons a {
    padding: 7px;
    transition: 100ms ease-in-out;
    display: flex;
    width: 30px;
    height: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius:6px;
    border: 1px solid #131828;
    color: white;
    margin: 0 4px;
    font-size: 1rem;
    background-color: #131828;
}

.paginationButtons a:hover {
    background: #00B9F7;
}


@media (min-width: 600px) {
    .paginationButtons{
        flex-wrap: nowrap;
        gap: 0;
    }
}

.paginationActive a {
    border: 1px solid #00B9F7;
    color: #00B9F7;
}

.paginationActive:hover a {
    background: #00B9F7;
    color: white;
}


.paginationDisabled a {
    cursor: not-allowed;
}

.paginationButtons svg {
    color: white;
}

.paginationDisabled svg {
    color: #505050;
}

.intelligence-icon svg {
    width: 16px;
    height: 16px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	background: #131828;
	font-family:
		'Roboto',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body {
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
